<template>
    <div>
        <textarea class="form-control" :class="classList" :name="name" :id="id" v-model="model" :placeholder="field.placeholder" :style="style"></textarea>
        <div v-if="field.failed_validation" class="invalid-feedback">{{ field.validation_message }}</div>
    </div>
</template>

<script>
export default {
    props: {
        field: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            model: null,
        }
    },

    computed: {
        name() {
            return 'input_' + this.field.id;
        },

        id() {
            return 'input_' + this.field.formId + '_' + this.field.id;
        },

        classList() {
            const classes = [];

            if(this.field.failed_validation) {
                classes.push('is-invalid');
            }

            return classes;
        },

        style() {    
            if(this.field.size == 'large') {
                return { height: '16em' };
            }

            if(this.field.size == 'small') {
                return { height: '8em' };
            }

            return { height: '6em' };
        },  
    }
}
</script>
