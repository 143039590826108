<template>
    <div>
        <div class="collapse mt-6" id="additionalPerks">
            <div class="row gx-0 gx-md-7 gy-4 gy-md-6 justify-content-center">
                <slot></slot>
            </div>
        </div>
        <div class="mt-7 text-center">
            <a href="#additionalPerks" class="careers-perks-toggle btn btn-outline-primary" @click.prevent="show = !show">
                {{ buttonLabel }}
            </a>
        </div>
    </div>
</template>

<script>
    import { Collapse } from 'bootstrap';

    export default {
        props: {
            type: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                collapse: null,
                show: false,
            }
        },  

        computed: {
            buttonLabel() {
                const amount = (this.show) ? 'Less' : 'More';

                return 'View ' + amount + ' ' + this.type + ' Benefits';
            }
        }, 
        
        watch: {
            show() {
                this.collapse.toggle();
            },
        },

        mounted() {
            this.collapse = new Collapse('#additionalPerks', {
                toggle: false,
            });
        },  
    }
</script>