<template>
    <div class="testimonials-nav mt-4 mt-md-6">
        <div v-if="carousel" class="testimonials-nav-items d-flex align-items-center justify-content-center">
            <button class="testimonials-nav-btn testimonials-nav-btn-prev" @click.prevent="prev">
                <inline-svg src="/app/themes/pappas/images/arrow.svg"></inline-svg>
            </button>
            <button v-for="(items, idx) in items" class="testimonials-nav-dot" :class="{'testimonials-nav-dot-active': idx == active}" :key="'testimonial-' + idx" @click.prevent="go(idx)"></button>
            <button class="testimonials-nav-btn testimonials-nav-btn-next" @click.prevent="next">
                <inline-svg src="/app/themes/pappas/images/arrow.svg"></inline-svg>
            </button>
        </div>
    </div>
</template>

<script>
import { Carousel } from 'bootstrap';

export default {
    props: {
        items: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            carouselEl: null,
            carousel: null,
            active: 0,
        };
    },

    created() {

    },

    mounted() {
        this.carouselEl = document.getElementById('testimonialsCarousel');
        this.carousel = Carousel.getOrCreateInstance(this.carouselEl);

        this.carouselEl.addEventListener('slide.bs.carousel', this.updateActive);
    },

    methods: {
        updateActive({ to }) {
            this.active = to;
        },

        prev() {
            this.carousel.prev();
        },

        next() {
            this.carousel.next();
        },

        go(index) {
            this.carousel.to(index);
        }
    },

}
</script>