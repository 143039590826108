<template>
    <div class="careers-search-banner">
        <form action="#results">
            <div class="row align-items-center justify-content-center gx-3">
                <div class="col-12 col-md-8 mb-2 mb-md-0">
                    <div class="input-group">
                        <input type="text" class="form-control" name="q" v-model="queryModel"
                            placeholder="Search by City & State or Zip Code" required>
                        <select name="r" class="form-select" v-model="radiusModel">
                            <option v-for="(key, value) in radiusOptions" :value="value">{{ key }}</option>
                        </select>
                    </div>
                </div>

                <div class="col-auto text-center mt-2 mt-md-0">
                    <button type="submit" class="btn btn-primary">
                        <inline-svg src="/app/themes/pappas/images/search.svg" class="me-2"></inline-svg>
                        <span>Search</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    props: {
        /**
         * Whether to display the search form title.
         */
        showTitle: {
            type: Boolean,
            default: true,
        },

        /**
         * The search geo query.
         */
        query: {
            type: String,
            default: null,
        },

        /**
         * The search radius.
         */
        radius: {
            type: Number,
            default: 20,
        },
    },

    data() {
        return {
            pristine: true,
            queryModel: this.query,
            radiusModel: this.radius,
            radiusOptions: {
                '5': '5 miles',
                '10': '10 miles',
                '15': '15 miles',
                '20': '20 miles',
                '30': '30 miles',
                '40': '40 miles',
                '50': '50 miles',
            },
        }
    },

    computed: {
        /**
         * The search form title.
         */
        title() {
            return (this.pristine) ? 'Search our locations' : 'Update location';
        },
    }
}
</script>