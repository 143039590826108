export default {
    methods: {
        /**
         * Whether a button object is valid.
         * 
         * @param  {Object} button 
         * @return {Boolean}
         */
        isValidButton(button) {
            return (button && button.label && button.url);
        },

        /**
         * Generate the button's standard attributes.
         * 
         * @param  {Object} button 
         * @return {Object} 
         */
        buttonAttrs(button) {
            const attrs = { href: button.url };

            if(button.external) {
                attrs.target = '_blank';
                attrs.rel = 'noopener';
            }

            return attrs;
        }
    }
}