<template>
    <div class="form-input mb-3 row">
        <label class="form-input-label col-form-label col-sm-2">
            <div>{{ field.label }} <span class="text-danger" v-if="field.isRequired">*</span></div>
        </label>
        <div class="col-sm-10">
            <component v-bind:is="componentName" :field="field" :class="inputClass"></component>
        </div>
    </div>
</template>

<script>
import { upperFirst, camelCase } from 'lodash';
import InputComponents from './Inputs';

export default {
    components: { InputComponents },

    props: {
        field: {
            type: Object,
            required: true,
        },

        inputClass: {
            type: [String, Object, Array],
            default: '',
        },
    },

    computed: {
        componentName() {
            let type = this.field.type;

            if(type == 'fileupload') {
                type = 'upload';
            }

            const name = upperFirst(camelCase(type) + 'Input');

            if(Object.keys(InputComponents).includes(name)) {
                return InputComponents[name];
            }

            return InputComponents.TextInput;
        },
    },
}
</script>
