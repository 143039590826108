<template>
    <div v-if="items" class="glide position-relative social-gallery-carousel" :class="wrapperClass">
        <div class="glide__track" data-glide-el="track">
            <div class="glide__slides">
                <div v-for="item in items" class="glide__slide" ref="slide">
                    <div :class="'ratio ratio-' + ratio" v-html="item"></div>
                </div>
            </div>
        </div>

        <div class="glide__arrows position-absolute d-flex align-items-center justify-content-between w-100 top-50 start-50 translate-middle"
            data-glide-el="controls">
            <button class="glide__arrow glide__arrow--left" data-glide-dir="<">
                <inline-svg src="/app/themes/pappas/images/arrow.svg"></inline-svg>
            </button>
            <button class="glide__arrow glide__arrow--right" data-glide-dir=">">
                <inline-svg src="/app/themes/pappas/images/arrow.svg"></inline-svg>
            </button>
        </div>
    </div>
</template>

<script>
import Glide, { Controls, Breakpoints, Swipe } from '@glidejs/glide/dist/glide.modular.esm'
import merge from 'deepmerge';

export default {
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        ratio: {
            type: String,
            default: '1x1',
        },
        options: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            glide: null,
            // isAtStart: true,
            // isAtEnd: false,
        }
    },

    computed: {
        glideOptions() {
            return merge({
                type: 'carousel',
                perView: 3,
                gap: 24,
                rewind: false,
                // bound: 3,
                peek: 168,
                breakpoints: {
                    1140: {
                        peek: 100,
                    },
                    900: {
                        perView: 2,
                        peek: 50,
                    },
                    576: {
                        perView: 1,
                        peek: 36,
                        gap: 5
                    },
                }

            }, this.options);
        },

        wrapperClass() {
            const classlist = [];

            return classlist;
        }
    },

    mounted() {
        this.glide = new Glide('.glide', this.glideOptions).mount({ Controls, Breakpoints, Swipe });

        this.glide.on(['mount.before', 'run'], () => {
            this.isAtStart = (!this.glide.index);

            this.isAtEnd = (this.glide.index >= this.$refs.slide.length - this.glide.settings.bound)
                ? true
                : false;
        });
    },
}
</script>