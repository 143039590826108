<template>
    <div v-if="items" class="glide position-relative" :class="wrapperClass">
        <div class="glide__track" data-glide-el="track">
            <div class="glide__slides">
                <div v-for="item in items" class="glide__slide" ref="slide">
                    <div :class="'ratio ratio-' + ratio" v-html="item"></div>
                </div>
            </div>
        </div>

        <div class="glide__arrows position-absolute d-flex align-items-center justify-content-between w-100 top-50 start-50 translate-middle"
            data-glide-el="controls">
            <button class="glide__arrow glide__arrow--left" data-glide-dir="<">
                <inline-svg src="/app/themes/pappas/images/arrow.svg"></inline-svg>
            </button>
            <button class="glide__arrow glide__arrow--right" data-glide-dir=">">
                <inline-svg src="/app/themes/pappas/images/arrow.svg"></inline-svg>
            </button>
        </div>
    </div>
</template>

<script>
import Glide, { Controls, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm'
import merge from 'deepmerge';

export default {
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        ratio: {
            type: String,
            default: '',
        },
        options: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            glide: null,
            isAtStart: true,
            isAtEnd: false,
        }
    },

    computed: {
        glideOptions() {
            return merge({
                perView: 3,
                gap: 24,
                rewind: false,
                bound: 3,
                breakpoints: {
                    900: {
                        perView: 2,
                        bound: 2,
                    },
                    576: {
                        perView: 1,
                        bound: 1,
                    },
                }

            }, this.options);
        },

        wrapperClass() {
            const classlist = [];

            if (this.isAtStart) {
                classlist.push('glide--start');
            }

            if (this.isAtEnd) {
                classlist.push('glide--end');
            }

            return classlist;
        }
    },

    mounted() {
        this.glide = new Glide('.glide', this.glideOptions).mount({ Controls, Breakpoints });

        this.glide.on(['mount.before', 'run'], () => {
            this.isAtStart = (!this.glide.index);

            this.isAtEnd = (this.glide.index >= this.$refs.slide.length - this.glide.settings.bound)
                ? true
                : false;
        });
    },
}
</script>