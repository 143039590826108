<template>
    <form class="newsletter-form d-flex" method="get" :action="dest" target="_blank">
        <select class="newsletter-form-select form-select" v-model="dest" required>
            <option :value="null">Select a restaurant</option>
            <option v-for="item in concepts" :value="item.value">{{ item.label }}</option>
        </select>

        <button type="submit" class="newsletter-form-submit btn" :disabled="!dest">Sign Up</button>
    </form>
</template>

<script>
export default {
    props: {
        concepts: {
            type: Array,
            required: true,
        }
    },

    data() {
        return {
            dest: null,
        }
    },
}
</script>