<template>
    <div class="careers-results-inner pb-0 pb-md-6">
        <div class="container-xxl mb-0 mb-md-6">
            <div class="careers-results-title fw-bold text-center text-pink mb-5">
                Showing job openings at {{ results.totalLocations }} locations 
                <template v-if="results.query">close to {{ results.query }}</template>
            </div>

            <div class="careers-results-list mx-auto">
                <div class="careers-results-view" id="searchResults">
                    <result-location v-for="(location, index) in results.locations" :key="location.id" :index="index" :location="location" :origin="results.coordinates"></result-location>
                </div>

                <nav v-if="results.pages > 1" class="careers-results-pagination mt-6">
                    <ul class="pagination justify-content-center">
                        <li v-if="canPrev" class="page-item page-item-seq">
                            <a class="page-link" :href="getPageUrl(results.page - 1)">Previous</a>
                        </li>
                        <li v-for="n in results.pages" class="page-item" :class="{active: results.page == n}" :key="'page-' + n">
                            <a class="page-link" :href="getPageUrl(n)">{{ n }}</a>
                        </li>
                        <li v-if="canNext" class="page-item page-item-seq">
                            <a class="page-link" :href="getPageUrl(results.page + 1)">Next</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import ResultLocation from './ResultLocation.vue';

export default {
    components: { ResultLocation },

    props: {
        results: {
            type: Object,
            required: true,
        },
    },

    computed: {
        canPrev() {
            return this.results.pages > 1 && this.results.page > 1;
        },

        canNext() {
            return this.results.pages > this.results.page;
        },
    },

    methods: {
        getPageUrl(page) {
            const url = new URL(window.location);

            url.searchParams.set('pg', page);

            return url;
        }
    }
}
</script>