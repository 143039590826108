export default {
    data() {
        return {
            stickyHeader: {
                el: null,
                navEl: null,
                sticky: false,
                thresholdMin: 120,
                threshold: 120,
            }
        }
    },

    mounted() {
        this.stickyHeader.el = this.$refs.siteHeader;
        this.stickyHeader.navEl = this.stickyHeader.el.querySelector('.site-header-nav');

        this.setThreshold();

        window.addEventListener('scroll', this.handleScroll);
    },

    methods: {
        setThreshold() {
            const bounds = this.stickyHeader.navEl.getBoundingClientRect();

            this.stickyHeader.threshold = (bounds.top > this.stickyHeader.thresholdMin) ? bounds.top : this.stickyHeader.thresholdMin;
        },
        
        handleScroll(event) {
            if(window.scrollY >= this.stickyHeader.threshold) {
                this.stickHeader();
            } else {
                this.unstickHeader();
            }
        },

        stickHeader() {
            this.stickyHeader.el.classList.add('site-header-fixed');
            this.stickyHeader.sticky = true;
        },

        unstickHeader() {
            this.stickyHeader.el.classList.remove('site-header-fixed');
            this.stickyHeader.sticky = false;
        }
    }
}