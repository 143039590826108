<template>
    <div class="careers-result-location border-bottom">
        <a :href="linkTarget" data-bs-toggle="collapse" aria-expanded="false" :data-bs-target="linkTarget" :aria-controls="id" class="careers-result-header d-block py-4 pe-3">
            <div class="row align-items-center justify-content-between">
                <div class="col-md-auto">
                    <div class="careers-result-header-details text-center text-md-start">
                        <span class="fw-bold text-primary">{{ location.name }}</span>
                        <address class="mb-0">{{ location.displayAddress }}</address>
                        <div v-if="distance" class="text-pink"><small>{{ distance }} mi</small></div>
                    </div>
                </div>
                <div class="col-md-auto text-center text-md-start mt-2 mt-md-0">
                    <button type="button" class="btn btn-sm btn-primary">View Openings</button>
                </div>
            </div>
        </a>

        <div class="collapse careers-result-jobs" ref="jobs" :id="id" data-bs-parent="#searchResults">
            <div v-for="(career, idx) in location.jobs" class="px-4 py-3 py-md-0" :key="career.id">
                <div class="row align-items-center justify-content-between py-1 py-md-2">
                    <div class="col-md-auto fw-bold text-primary mb-2 mb-md-0">{{ career.title }}</div>
                    <div class="col-auto">
                        <div class="row flex-row-reverse flex-md-row gx-3">
                            <div class="col-auto">
                                <a :tabindex="idx" data-bs-toggle="popover" :data-bs-content="popoverContent(career)" ref="sharePopover" class="text-primary">
                                    Share
                                </a>
                            </div>
                            <div class="col-auto"><a :href="career.permalink" class="btn btn-primary btn-xs">More Info</a></div>
                            <div class="col-auto"><a :href="career.apply_url" class="btn btn-primary btn-xs" target="_blank" rel="noopener">Apply Now</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Popover } from 'bootstrap'
import haversine from 'haversine';

export default {
    props: {
        index: {
            type: Number,
            required: true,
        },

        /**
         * The result location object.
         */
        location: {
            type: Object,
            required: true,
        },

        /**
         * Search origin lat/lng.
         */
        origin: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            id: 'location-' + this.index,
            collapse: null,
            popover: null,
        }
    },

    computed: {
        linkTarget() {
            return '#' + this.id
        },

        /**
         * Calculated distance from search location.
         */
        distance() {
            if(!this.origin) {
                return;
            }

            const distance = haversine({
                latitude: this.origin.lat,
                longitude: this.origin.lng,
            }, {
                latitude: this.location.latitude,
                longitude: this.location.longitude,
            }, {
                unit: 'mile'
            });

            return (Math.round(distance * 100) / 100).toFixed(2);
        },
    },

    mounted() {
        this.initPopover();
    },

    methods: {
        initPopover() {
            const opts = {
                html: true,
                trigger: 'focus',
                sanitize: false,
                placement: 'left',
            };

            this.popover = this.$refs.sharePopover.map(el => new Popover(el, opts));
        },

        popoverContent(career) {
            let html = '<div class="row gx-3 align-items-center">';

            Object.values(career.share).forEach((service) => {
                html += '<div class="col-auto career-share-social">';
                    html += '<a href="' + service.url + '" target="_blank" rel="noopener">';
                        html += '<img src="' + service.icon + '" alt="" style="height:100%; width: auto;">';
                    html += '</a>';
                html += '</div>';

            });

            html += '</div>';

            return html
        }
    }
}
</script>