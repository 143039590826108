<template>
    <div class="contact-form-header text-center mx-auto mb-4 mb-md-7">
        <div class="h4 text-pink">{{ activeType.headline }}</div>
        <div class="mt-3 mt-md-4" v-html="activeType.content"></div>
    </div>
</template>

<script>
export default {
    props: {
        type: {
            type: [String, Boolean],
            default: false,
        },
        types: {
            type: Array,
            default: () => [],
        },
    },

    computed: {
        defaultType() {
            return this.types[0];    
        },

        activeType() {
            const type = this.types.find((type) => type.subject_type == this.type);
            return (type) ? type : this.defaultType;
        },
    },
}
</script>
