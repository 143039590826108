<template>
    <div ref="socialImpactCarousel" class="glide">
        <div class="glide__track" data-glide-el="track">
            <ul class="glide__slides list-unstyled position-relative h-100 d-flex align-items-stretch">
                <li v-for="(item, idx) in items" class="glide__slide bg-blue-200" :key="'carouselItem-' + idx">
                    <div class="home-social-impact-panel h-100 py-4 px-3 py-sm-5 px-sm-4 d-flex flex-column h-100">
                        <div class="home-social-impact-panel-img ratio ratio-1x1">
                            <div>
                                <img :src="item.image.url" :alt="item.label" class="object-fit-contain">
                            </div>
                        </div>
                        <div class="home-social-impact-panel-label fw-bold text-primary my-3">
                            {{ item.label }}
                        </div>
                        <div class="home-social-impact-panel-content" v-html="nl2br(item.content)"></div>

                        <div v-if="isValidButton(item.button)" class="mt-3">
                            <a v-bind="buttonAttrs(item.button)">{{ item.button.label }}</a>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <div class="glide__arrows position-absolute d-flex align-items-center justify-content-between w-100 top-50 start-50 translate-middle"
            data-glide-el="controls">
            <button class="glide__arrow glide__arrow--left" data-glide-dir="<">
                <inline-svg src="/app/themes/pappas/images/arrow.svg"></inline-svg>
            </button>
            <button class="glide__arrow glide__arrow--right" data-glide-dir=">">
                <inline-svg src="/app/themes/pappas/images/arrow.svg"></inline-svg>
            </button>
        </div>
    </div>
</template>

<script>
import Glide, { Controls, Breakpoints, Swipe } from '@glidejs/glide/dist/glide.modular.esm';
import handlesButton from '../../mixins/handles-button';

export default {
    mixins: [ handlesButton ],

    props: {
        items: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            carousel: null,
            glideOpts: {
                perView: 3,
                breakpoints: {
                    1000: {
                        perView: 2,
                        gap: 15,
                    },
                    800: {
                        gap: 0,
                        perView: 1
                    },
                },
                gap: 30,
                bound: 2,

            },
        }
    },

    mounted() {
        // Breakpoints.match({
        //     400: { perView: 1 },
        //     500: { perView: 3 }
        // });
        new Glide(this.$refs.socialImpactCarousel, this.glideOpts).mount({ Controls, Breakpoints, Swipe });
    },

    methods: {
        nl2br(str) {
            const replaceStr = '$1'+ '<br>' +'$2';
            
            return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
        },
    }
}
</script>