<template>
    <div class="px-4 py-3 py-md-0">
        <div class="row align-items-center justify-content-between py-1 py-md-2">
            <div class="col-md-auto fw-bold text-primary mb-2 mb-md-0">{{ item.title }}</div>
            <div class="col-auto">
                <div class="row flex-row-reverse flex-md-row gx-3">
                    <div class="col-auto">
                        <a
                            data-bs-toggle="popover"
                            :data-bs-content="popoverContent"
                            ref="sharePopover"
                            class="text-primary"
                        >
                            Share
                        </a>
                    </div>
                    <div class="col-auto"><a :href="item.permalink" class="btn btn-primary btn-xs">More Info</a></div>
                    <div class="col-auto">
                        <a :href="item.apply_url" class="btn btn-primary btn-xs" target="_blank" rel="noopener">
                            Apply Now
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Popover } from 'bootstrap';

export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    computed: {
        popoverContent() {
            let html = '<div class="row gx-3 align-items-center">';

            Object.values(this.item.share).forEach((service) => {
                html += '<div class="col-auto career-share-social">';
                html += '<a href="' + service.url + '" target="_blank" rel="noopener">';
                html += '<img src="' + service.icon + '" alt="" style="height:100%; width: auto;">';
                html += '</a>';
                html += '</div>';
            });

            html += '</div>';

            return html;
        },
    },
};
</script>
