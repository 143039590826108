<template>
        <div>
            <input :type="inputType" class="form-control" :class="classList" :name="name" :id="id" v-model="model" :placeholder="field.placeholder">
            <div v-if="field.failed_validation" class="invalid-feedback">{{ field.validation_message }}</div>
        </div>

</template>

<script>
export default {
    props: {
        field: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            model: null,
        }
    },

    computed: {
        name() {
            return 'input_' + this.field.id;
        },

        id() {
            return 'input_' + this.field.formId + '_' + this.field.id;
        },

        inputType() {
            if(this.field.type == 'phone') {
                return 'tel';
            }

            return this.field.type;
        },


        classList() {
            const classes = [];

            if(this.field.failed_validation) {
                classes.push('is-invalid');
            }

            return classes;
        },


    }
}
</script>
